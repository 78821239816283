import axios from 'axios';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL;

export async function signInService(param){
    return await axios.post(API_URL+`/signin`, param); 
}

export async function getDrugReportService(param){
    return await axios.post(API_URL+`/dna/pharmcard/get`, param);    
}

export function convertDateDDMMYYYY(date){
    const yyyyMMdd = Date.parse(date.substring(0,10));
    return moment(yyyyMMdd).format('DD/MM/YYYY');
}
 
export async function checkPhoneNumber(number){
    try {
        const response = await axios.post(`${API_URL}/dna/pharmcard/auth`, number);    
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function sendOTP(phone_number){
    try {
        const response = await axios.post(`${API_URL}/signin/otp/send`, phone_number);    
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function confirmOTP(data){
    try {
        const response = await axios.post(`${API_URL}/signin/otp/confirm`, data);    
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getDrugReportDemo(param){
    return await axios.post(API_URL+`/dna/pharmcard/get/noauth`, param);    
}

export async function checkSampleID(number){
    try {
        const response = await axios.post(`${API_URL}/dna/pharmcard/sample-id/check`, number);    
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getDrugReportBySampleID(param){
    return await axios.post(API_URL+`/dna/pharmcard/sample-id/get`, param);    
}