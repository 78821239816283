import React from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import kinyoodeeImg from "../../assets/img/kinyoodee.png";
import { checkPhoneNumber, sendOTP, checkSampleID } from "../../api/helper";
import '../../assets/css/style.css';
import session from '../../session.js';
import { useEffect } from "react";

const SignIn = () => {

  const checkNumber = async (e) => {
    e.preventDefault();
    const number = document.getElementById('phone_number').value;
    if (number.length == 8) {
      const check = await checkSampleID({ token: session, number: number });
      if (check.error === 0) {
        window.localStorage.setItem('number', number);
        window.location.href = `/dashboard`
      } else {
        Swal.fire({
          icon: 'error',
          title: "Fails",
          text: check.errmsg
        });
      }
    } else if (number.length == 10) {
      requestOTP(number);
    } else {
      Swal.fire({
        icon: 'error',
        title: "Fails",
        text: "You entered an incorrect number."
      });
    }
  }

  const requestOTP = async (phoneNumber) => {
    let check = await checkPhoneNumber({token: session, number: phoneNumber });
    if (check.error === 0) {
      let otp = await sendOTP(check.data);
      if (otp.error === 0) {
        window.location.href = `/signin/verification?phone=${phoneNumber}`
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: "Fails",
        text: check.errmsg
      });
    }
  };

  useEffect(() => {
    const number = window.localStorage.getItem('number');
    if (number) {
      window.location.href = '/dashboard';
    }
  })
  return (
    <div className="container" style={{height : "100%"}}>
      {/* <!-- Outer Row --> */}
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 align-middle">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0 my-5">
              {/* <!-- Nested Row within Card Body --> */}
              <div className="row">
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"/>
                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                  <div className="p-2">
                    <div className="text-center">
                      <img src={kinyoodeeImg} style={{ height: "30%", width: "30%" }} alt="logo" />
                    </div>
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4 mt-4" style={{ fontWeight: "bold" }}>KinYooDee Platform</h1>
                    </div>
                    <div className="text-center">Please enter phone number to receive verification code</div>
                    <form onSubmit={(e) => checkNumber(e)} className="user">
                      <div className="form-group">
                        <label htmlFor="phone_number"></label>
                        <input type="number" className="form-control form-control-user" id="phone_number" name="phone_number" placeholder="Phone number" required 
                          style={{ fontSize: "16px" }} />
                      </div>
                      <button type="submit" className="btn btn-primary btn-user btn-block"  style={{ fontSize: "16px" }}>
                      Request OTP
                      <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10" style={{ width: "16px", marginLeft: "10px" }}>
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                      </svg>                        
                    </button>
                    </form>
                  </div>
                </div>
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default withRouter(SignIn);
